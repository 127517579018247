import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FirstNavbar from '../components/First_navabr';
import { Context } from '../context/Context_provider';
import { toast } from 'react-toastify';
import { setAccessToken } from './authService';
import axiosInstance from './axiosInstance';
import { ipaddress } from '../App';

const Loginpage = ({ setemailvalidation }) => {
  const { translate_value } = useContext(Context);
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [password_type, setPassword_type] = useState(false);

  const useridData = (e) => {
    setUserid(e.target.value);
  };
  const passwordData = (e) => {
    setPassword(e.target.value);
  };

  let navigate = useNavigate();
  const handleSubmit = async () => {
    await axiosInstance.post(`${ipaddress}/CheckEmailForThreeMonths/${userid}/`)
      .then((r) => {
        if (r.data.message === "You can access this view.") {
          verifiedlogin();
          setUserid("");
          setPassword("");
        }
        if (r.data.message === "Please re-verify your email.") {
          setemailvalidation(true);
        }
      })
      .catch((err) => {
        toast.error('Email not verified', {
          autoClose: 3000,
        });
      });
  };

  const [loading, setloading] = useState();

  const generate_token = (e) => {
    e.preventDefault();
    axios.post(`${ipaddress}/api/token/`, {
      email: userid,
      password: password
    })
      .then((r) => {
        setAccessToken(r.data.access, r.data.refresh);
        handleSubmit();
      })
      .catch((err) => {
        console.error("Token error", err);
        toast.error('Invalid Email and Password', {
          autoClose: 3000,
          theme:'colored',
          position:'top-center'
        });
      });
  };

  const verifiedlogin = () => {
    setloading(true);
    const values = { userid, password };
    axiosInstance.post(`${ipaddress}/UserLogin/`, values)
      .then((r) => {
        sessionStorage.setItem("user", JSON.stringify(r.data));
        setloading(false);
        navigate('/dashboard/page');
        setUserid("");
        setPassword("");
      })
      .catch((err) => {
        setUserid("");
        setPassword("");
        toast.error('Invalid Email and Password', {
          autoClose: 3000,
        });
        setloading(false);
      });
  };

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState();
  const verifyemaildata = (e) => {
    setEmail(e.target.value);
  };
  const verifyEmail = (e) => {
    e.preventDefault();
    axiosInstance.post(`${ipaddress}/userverification/`)
      .then((r) => {
        setOtp(r.data.otp);
      });
  };

  const [newotp, setNewotp] = useState("");
  const otpdata = (e) => {
    setNewotp(e.target.value);
  };
  const submitOtp = (e) => {
    e.preventDefault();
    if (newotp === otp) {
      axiosInstance.put(`${ipaddress}/CheckEmailForThreeMonths/${userid}/`)
        .then((r) => {
          setNewotp("");
        });
    }
  };

  const [forgotpassmail, setForgotpassemail] = useState("");
  const forgotpassword = () => {
    const formdata = new FormData();
    formdata.append('email', forgotpassmail);
    formdata.append('url', 'https://lernen-hub.de/forgot_password/');
    axiosInstance.post(`${ipaddress}/ForgetPassword/`, formdata)
      .then((r) => {});
  };

  return (
    <div className='bg-light pb-4' style={{ minHeight: '100vh' }}>
      <div className="container animate__animated animate__fadeIn">
        <FirstNavbar />
      </div>
      <div className="row m-0 h-100 my-4">
        
        <div className="col-lg-6 pb-3">
          <div className="d-flex justify-content-center flex-column align-items-center h-100" style={{ marginLeft: 'auto' }}>
            <div className="d-flex justify-content-center flex-column flex-lg-row border rounded-3 overflow-hidden shadow-sm" style={{ flex: '0 0 100%', maxWidth: '900px' }}>
              <div className="text-white d-flex flex-column shadow rounded justify-content-center align-items-start p-4 d-none d-lg-flex" style={{ flex: '0 0 40%', background: 'linear-gradient(25deg, #f2f0ff, #f2f0ff)', minWidth: '250px' }}>
                <h3 className="page6-month mb-3" style={{ fontSize: '30px', color: '#FFFFFF' }}>Highlights</h3>
                <ul className="list-unstyled" style={{ fontSize: '20px', color: '#FFFFFF' }}>
                  <li className="page6-month mb-3" style={{ fontSize: '20px' }}>
                    <i className="fas fa-check-circle me-2" style={{ color: '#FFFFFF' }}></i>
                    All Document Access
                  </li>
                  <li className="page6-month mb-3" style={{ fontSize: '20px' }}>
                    <i className="fas fa-check-circle me-2" style={{ color: '#FFFFFF' }}></i>
                    Ad-Free Learning
                  </li>
                  <li className="page6-month mb-3" style={{ fontSize: '20px' }}>
                    <i className="fas fa-check-circle me-2" style={{ color: '#FFFFFF' }}></i>
                    Anonymous Posting
                  </li>
                  <li className="page6-month mb-3" style={{ fontSize: '20px' }}>
                    <i className="fas fa-check-circle me-2" style={{ color: '#FFFFFF' }}></i>
                    Private Study Groups
                  </li>
                  <li className="page6-month mb-3" style={{ fontSize: '20px' }}>
                    <i className="fas fa-check-circle me-2" style={{ color: '#FFFFFF' }}></i>
                    And much more!
                  </li>
                </ul>
              </div>

              <div className='bg-white shadow rounded pt-4 pb-2 px-4 signup-form2 d-flex flex-column align-items-center' style={{ height: '620px', flex: '0 0 60%' }}>
                <h3 className='page6-month mb-3' style={{ fontSize: '35px', color: '#5D5FE3' }}>{translate_value.login_page.login}</h3>
                <div className="w-100">
                  <form className='p-2 px-2 ' onSubmit={generate_token}>
                    <div className="mb-4">
                      <label htmlFor="floatingInput" style={{ color: '#8E9696' }} className='mb-2'>{translate_value.login_page.email}</label>
                      <input type="text" name='userid' className="form-control shadow-none bg-light" id="floatingInput" style={{ height: '50px', width: '350px'}} onChange={useridData} value={userid} />
                    </div>
                    <div className="mb-2">
                      <label htmlFor="floatingPassword" style={{ color: '#8E9696' }} className='mb-2'>{translate_value.login_page.password}</label>
                      <div className="input-group mb-3 bg-light border rounded">
                        <input type={password_type ? "text" : "password"} name='password' className="form-control shadow-none border-0 bg-transparent" style={{ height: '50px' }} id="floatingPassword" onChange={passwordData} value={password} />
                        <span onClick={() => {
                          setPassword_type(!password_type)
                        }} style={{ cursor: 'pointer' }} className="input-group-text border-0 bg-transparent text-secondary" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                          <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                          <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                        </svg></span>
                      </div>
                    </div>
                    <div className='text-end mb-4'>
                      <a href="" style={{ color: '#FF845D', textDecoration: 'none' }} data-bs-toggle="modal" data-bs-target="#forgotpassword">{translate_value.login_page.forgot_password}</a>
                    </div>

                  {/* Login button actual from Bteam*

                    <div className="text-center">
                      <button type='submit' className='btn Login-btn btn-md py-2 px-5 text-white fw-medium'> {loading ? 'Loading...' : `${translate_value.login_page.submit}`}</button>
                    </div>
                  {/* Login button actual from Bteam*/}
                  {/* Start Login button changed by VA*/}
                  <div className="text-center">
                    <button type='submit' className='btn Login-btn btn-md py-2 px-5 text-white fw-medium' style={{
                      color: '#fff',
                      fontSize: '14px',
                      border: '1px solid #8587EA',
                      fontWeight: 450,
                      letterSpacing: '0.32px',
                      lineHeight: '22px',
                      padding: '10px 20px',
                      borderRadius: '10px',
                      transition: 'all 0.3s ease',
                      backgroundColor: '#8587EA', // Initial background
                      textDecoration: 'none',
                      overflow: 'hidden'
                    }} onMouseEnter={(e) => {
                      e.currentTarget.style.paddingLeft = '10px 40px'; // Expand on hover
                      e.currentTarget.style.background = 'linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)'; // Change background color on hover
                      e.currentTarget.style.color = 'white'; // Change text color on hover
                    }} onMouseLeave={(e) => {
                      e.currentTarget.style.paddingLeft = '10px 20px'; // Reset size on mouse leave
                      e.currentTarget.style.paddingRight = '20px'; // Reset size on mouse leave
                      e.currentTarget.style.background = '#8587EA'; // Reset background color
                      e.currentTarget.style.color = '#fff'; // Reset text color
                    }}>
                      {loading ? 'Loading...' : `${translate_value.login_page.submit}`}
                    </button>
                  </div>
                    {/* Start Login button changed by VA*/}

                    <div className='m-0 mt-auto pb-2 text-secondary'>
                      
                        <p className='m-2'>Facing problem signing in... </p>

                        <p className='m-0 mt-auto pb-2 text-secondary' 
                        style={{ fontSize: '14px' }}>
                          <span 
                            style={{ color: '#5d5fe3', cursor: 'pointer', marginLeft: '45px' }} 
                            className='page6-month mb-2' 
                            onClick={() => navigate('/contact_us')}
                          >
                          Need Assistance?
                          </span>
                        </p>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mt-4 mt-lg-0 align-items-center">
          <div className='row m-0 mt-5 mt-lg-0 align-items-center' style={{ position: 'relative' }}>
          <div className='main-login align-items-center ' style={{position:'relative', padding: '20px'}}>
            <p style={{ letterSpacing: '3px' }}>The Student Community</p>
            <h1 className='fw-bold login-header' style={{ color: '#2A3941' }}>Improve</h1>
            <h1 className='fw-bold login-header' style={{ color: '#2A3941' }}>comprehension</h1>
            <h1 className='fw-bold login-header' style={{ color: '#FF845D' }}>together</h1>
            <svg className='login-img3 d-none d-lg-block' style={{ position: 'absolute', animation: 'spin 6s linear infinite' }} xmlns="http://www.w3.org/2000/svg" width="78" height="78" viewBox="0 0 78 78" fill="none">
              <path d="M28.43 77.66L0 49.23L0.0700073 48.96L10.4 10.4L10.67 10.33L49.23 0L77.66 28.43L77.59 28.7L67.26 67.26L66.99 67.33L28.43 77.66ZM1.08002 48.95L28.72 76.59L66.48 66.47L76.6 28.71L48.96 1.06998L11.2 11.19L1.08002 48.95Z" fill="#5D5FE3" />
            </svg>
            <img className='login-img1 align-items-center d-lg-block' style={{ position: 'absolute', animation: 'moveAnimation 6s linear infinite', width: '115px', height: '127px' }} src={require('../img//images_icons/Group-removebg-preview.png')} alt="" />
            <img className='login-img2 align-items-center  d-lg-block' style={{ position: 'absolute', height: '135px', width: '31px' }} src={require('../img/images_icons/login-image.png')} alt="" />
            <p className='mt-3 align-items-center ' style={{ color: '#2A3941', fontSize: '32px' }}>{translate_value.login_page.dont_have_account}</p>
            <Link to='/signuppage' className='btn p-3 px-5 fw-bold' style={{ color: '#5D5FE3', border: '1px solid #5D5FE3' }}> {translate_value.login_page.get_started}</Link>
          </div>
        </div>
      </div>
      </div>

      <div className="modal fade modal-md" id="verifyemail" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="d-flex p-2">
              <button type="button" className="btn-close ms-auto" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div>
              <h3 className=" fw-bold text-center fs-2 text-primary" id="staticBackdropLabel">Enter Your Email</h3>
            </div>
            <div className="modal-body">
              <form action="" className='p-2 px-4' onSubmit={verifyEmail}>
                <div className="form-floating mb-4">
                  <input type="text" name='userid' className="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={verifyemaildata} value={email} />
                  <label htmlFor="floatingInput" className='fw-normal'>Email</label>
                </div>
                <div className="text-center">
                  <button type='submit' className='btn Login-btn btn-md py-3 w-100 text-white fw-medium' data-bs-toggle="modal" data-bs-target="#otpmodal">Verify Email</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="otpmodal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h1 className="modal-title fs-5 mx-auto" id="exampleModalToggleLabel2">Enter the OTP</h1>
            </div>
            <div className="modal-body">
              <form action="" className="p-2 px-4" onSubmit={submitOtp}>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-floating mb-4">
                      <input onChange={otpdata} value={newotp}
                        type="text"
                        className="form-control shadow-none"
                        id="floatingInput"
                        placeholder="name@example.com"
                        name="first_name"
                      />
                      <label htmlFor="floatingInput" className="fw-normal" >
                        Enter Your OTP
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className="btn signup-btn btn-md py-3 w-100 text-white fw-medium"
                    type="submit" data-bs-target="#login" data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modal-md" id="forgotpassword" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body pb-5">
              <div className="d-flex">
                <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i className="fa-solid fa-circle-xmark fs-5"></i></button>
              </div>
              <div className='py-3'>
                <h6 className=" fw-bold text-center fs-3 text-dark" id="staticBackdropLabel">Forgot Password</h6>
              </div>
              <form action="" className='p-2 mt-3 px-4 mb-5'>
                <p className='text-center'>Please enter your registered email ID</p>
                <div className="form-floating mb-4">
                  <input type="text" name='userid' className="form-control shadow-none" id="floatingInput" placeholder="name@example.com" onChange={(e) => {
                    setForgotpassemail(e.target.value);
                  }} value={forgotpassmail} />
                  <label htmlFor="floatingInput" className='fw-normal'>Email</label>
                </div>
                <div className="text-center mt-5">
                  <button type='submit' className='btn Login-btn btn-md py-2 w-100 text-white fw-medium'
                    data-bs-toggle="modal" data-bs-target="#forgotpasswordsuccessfull"
                    onClick={forgotpassword}>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modal-md" id="forgotpasswordsuccessfull" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body pb-5">
              <div className='d-flex flex-column align-items-center justify-content-center' style={{ height: '300px' }}>
                <img src={require('../img/check__2_-removebg-preview.png')} width={70} alt="" />
                <p className='m-0 mt-3' style={{ color: '#34a853' }}>We've Sent the link to reset the password</p>
                <p className='m-0' style={{ color: '#34a853' }}>in your registered Email ID</p>
                <span style={{ color: '#34a853' }}>Please check your Inbox</span>
              </div>
              <div>
                <button className='btn' data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="toast-container position-fixed top-0 end-0 p-3">
        <div id="liveToast2" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-body d-flex justify-content-between px-4 py-2">
            <span id='toastbody2'></span>
            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
